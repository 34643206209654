/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
//@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  tap-highlight-color: rgba(0,0,0,0);
  background-color: $bodyBg;
}

// .page-wrap {
//   position: relative;

//   @media #{$medium} {
//     min-height: 100vh;
//     z-index: 2;
//   }
// }

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--medium {
  max-width: 1380px;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body { visibility: hidden; }
}

::selection {
  background-color: $selection;
  color: inherit;
}


/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
  font-style: normal;
  fill: $accent;
}

.icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
  width: 24px; position: absolute; left: 0;
  height: 1px; border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before, &:after { content: ""; margin-left: 0; }
  &:before { margin-top: 3px; }
  &:after { margin-top: 9px; }
}

.icon-instagram {
  width: 30px;
  height: 30px;
}

.icon-arrow-double {
  width: 22px;
  height: 9px;
}

.icon-download {
  width: 14px;
  height: 18px;
}

.icon-scroll-down {
  @include fs(30);
  color: #320707;
  font-style: normal;

  &:before {
    content: "»";
    display: block;
    transform: rotate(90deg);
  }
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative; padding: 0 0 56.25%; height: 0; overflow: hidden; margin: 0 0 15px;

  iframe {
    position: absolute; top:0; left: 0; width: 100%; height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before, &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div { float: left; }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
	position: relative;
  cursor: pointer;
}

.block-link__target {
	&:before {
		content: ""; display: block;
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
	}
}


/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 30px 20px 10px;
  background: #1a1a1a url('../images/logo-icon-black.svg') center center no-repeat;
  background-size: 600px 773px;

  @media #{$large} {
    position: fixed;
    top: 0; left: 0;
    width: #{$headerWidthMdLarge}px;
    min-height: 100vh;
    float: left;
    padding: 40px 30px 0;
  }

  @media #{$huge} {
    width: #{$headerWidthMdHuge}px;
    padding-left: 65px;
    padding-right: 65px;
  }

  @media #{$headerFullHeight} {
    background-size: 900px 1160px;
    padding-top: 60px;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  margin-bottom: 20px;
  display: block;
  max-width: 100%;
  img {
    display: block;
  }

  &:hover, &:focus {
    @include opacity(80);
  }

  @media #{$maxLarge} {
    max-width: 180px;
  }

  @media #{$large} {
    border-bottom: 1px solid #848484;
    margin-bottom: 20px;
    float: left;

    img {
      margin-bottom: 30px;
    }
  }

  @media #{$headerMinHeight} {
    margin-bottom: 25px;

    img {
      margin-bottom: 35px;
    }
  }

  @media #{$headerFullHeight} {
    margin-bottom: 40px;

    img {
      margin-bottom: 50px;
    }
  }
}

.main-nav-trigger {
  display: block; position: relative; padding: 12px 40px 12px 0;
  background-color: transparent; color: $black; font-weight: 500;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
  position: absolute; top: 30px; right: 20px;
  @include optimize-font;
  @include fs(21);
  color: #fff;

  .icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
    right: 0; left: inherit;
  }

  .icon-hamburger {
    position: absolute; top: 18px;
  }

  &:hover, &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$large} {
    display: none;
  }
}

.main-nav-wrap {
  display: none;
  margin: 30px 0 0;

  @media #{$maxLarge} {
    width: calc(100vw - 40px);
    border-top: 1px solid #848484;
    padding-top: 10px;
  }

  @media #{$large} {
    display: block!important;
  }
}

.main-nav {
  @include optimize-font;
  @include fs(21);
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;

  > ul {
    display: block;

    > li {
      margin-bottom: 5px;

      &.active {
        > a {
          &:before {
            content: "»";
            display: inline-block;
            margin-right: 12px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    padding: 9px 15px;
    display: inline-block;
  }

  a:hover, a:focus, li.active > a {
    color: $accent;
  }

  @media #{$large} {
    margin-bottom: 50px;

    > ul {
      margin: 0 0 0 -20px;
    }
  }

  @media #{$headerMeanHeight} {
    > ul > li {
      margin-bottom: 15px;
    }
  }

  @media #{$headerMinHeight} {
    margin-bottom: 20px;
  }

  @media #{$headerMeanHeight} {
    margin-bottom: 50px;

    a {
      padding: 10px 15px;
    }
  }

  @media #{$headerFullHeight} {
    margin-bottom: 100px;
  }
}

.main-nav__dropdown {
  @include fs(14);
  font-family: $sansFont;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 0 30px;
  display: none;

  li {
    margin-bottom: 3px;

    a {
      padding-left: 15px;
      position: relative;
      display: block;

      &:before {
        content: "›";
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
      }
    }
  }

  @media #{$huge} {
    margin-left: 40px;
  }
}

.main-nav__dropdown-toggle {
  color: $accent;
  display: inline-block;
  padding: 0 5px;
  background-color: transparent;
  position: relative;
  top: 0;
  opacity: 0.8;
  @include fs(15);
  font-weight: 800;
  font-family: $sansFont;
  width: 18px;
  height: 18px;
  border: 1px solid $accent;

  .icon {
    margin: 0;
    position: relative;
    top: -4px;
    left: -1px;
  }

  &[area-expanded="true"] {
    .icon {
      left: 0;
    }
  }

  &:hover, &:focus {
    opacity: 1;
    border-color: #fff;

    .icon {
      color: #fff;
    }
  }
}

@media #{$large} and #{$headerMinHeight} {
  .main-nav__dropdown {
    display: block;
  }

  .main-nav__dropdown-toggle {
    display: none;
  }
}

.lang {
  border: 14px solid inherit;
  border-width: 14px;
  border-style: solid;
  border-image: url('../images/border.svg') 20 round;
  display: inline-block;
  margin-bottom: 10px;

  @media #{$large} {
    margin-bottom: 0;
    position: absolute;
    bottom: 30px;
    height: 50px;
  }

  @media #{$huge} and #{$headerMeanHeight} {
    bottom: 65px;
  }
}

.lang__list {
  @include clearfix;
  list-style: none;
  text-transform: uppercase;
  @include fs(12);
  font-family: $sansFont;
  letter-spacing: 1px;
  margin: -14px;

  a {
    display: block;
    text-decoration: none;
    color: $accent;
    height: 50px;
    padding: 0 18px;
    line-height: 50px;
  }

  li.active a, a:hover, a:focus {
    color: #fff;
  }

  li {
    float: left;
    margin: 0;

    + li a {
      border-left: 1px solid $accent;
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}


/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  overflow: hidden;
  @include clearfix;

  @media #{$large} {
    padding-left: #{$headerWidthMdLarge}px;
    min-height: 100vh;
  }

  @media #{$huge} {
    padding-left: #{$headerWidthMdHuge}px;
  }
}

.page-content {
  @include clearfix;
  background-color: #fff;
  padding: 20px;

  @media #{$medium} {
    width: 50%;
    max-width: #{$pageContentMaxWidth}px;
    float: left;
  }

  @media #{$large} {
    padding: 65px 50px 55px;
    min-height: 100vh;
  }

  @media #{$larger} {
    padding-top: 75px;
  }

  @media #{$massive} {
    padding-top: 105px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.page-content--offset {
  position: relative;

  @media #{$medium} {
    z-index: 500;
    margin-top: -250px;
    padding-top: 50px;
  }

  @media #{$massive} {
    padding-top: 90px;
    margin-top: -300px;
  }
}

.page-aside {
  background: url('../images/page-aside-bg.jpg') center center no-repeat;
  background-size: cover;
  padding: 20px;
  min-height: 350px;



  @media #{$maxMedium} {
    + .page-content {
      padding-top: 30px;
    }
  }

  @media #{$medium} {
    position: fixed;
    right: 0;
    top: 0;
    min-height: 100vh;
    width: 50%;
    padding: 30px 40px;
    z-index: 1;
  }

  @media #{$large} {
    width: calc(50% - #{$headerWidthMdLarge / 2}px);
  }

  @media #{$larger} {
    padding: 50px;
  }

  @media #{$huge} {
    width: calc(50% - #{$headerWidthMdHuge / 2}px);
  }

  @media (min-width: #{$headerWidthMdHuge + $pageContentMaxWidth + 720}px) {
    left: #{$headerWidthMdHuge + $pageContentMaxWidth}px;
    width: auto;
  }

  @media #{$massive} {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.page-aside--overlay {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    z-index: -1;
    pointer-events: none;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
}

.page-aside__content {
  @media #{$medium} {
    max-width: 560px;
  }
}

.page-aside--align {
  @media #{$large} {
    padding-top: 75px;
  }

  @media #{$massive} {
    padding-top: 105px;
  }
}

.page-aside--scrollable {
  @media #{$medium} {
    position: absolute;
  }
}

.page-aside--scrollable-bottom {
  @media #{$medium} {
    position: fixed;
    bottom: 0;
    top: inherit;
  }
}

.page-aside__jumbotron {
  margin: 20px;
  background-color: #fff;
  padding: 20px;

  &:before {
    position: absolute; content: ""; display: block; z-index: -1;
    top: 50px; left: 25px; right: 25px; bottom: 15px;
    background-color: #69341b;
    box-shadow: 0px 35px 120px rgba(105, 51, 24, 0.9);
  }

  @media #{$medium} {
    padding: 40px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    max-width: 620px;
  }

  @media #{$huge} {
    padding: 65px;
    bottom: 40px;
    left: 40px;
    right: 40px;
  }

  @media #{$massive} {
    bottom: 100px;
    left: 100px;
  }
}

.page-aside__visual-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media #{$medium} {

  }
}

.page-aside__visual {
  max-height: 100%;

  @media #{$medium} {
    padding-top: 40px;
    padding-right: 40px;

    img {
      max-height: calc(100vh - 140px);
    }
  }

  @media #{$large} {
    padding-right: 80px;
    padding-left: 20px;
    padding-top: 20px;
  }
}

.back-button {
  float: right;
  display: block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-family: $sansFont;
  letter-spacing: 1px;
  @include fs(10);

  &:hover, &:focus {
    color: rgba(255,255,255,0.8);

    .icon {
      transform: translateX(2px);
    }
  }
}

.back-button--dark {
  color: $black;

  .back-button__icon .icon {
    fill: $black;
  }

  &:hover, &:focus {
    color: rgba(0,0,0,0.6);
  }
}

.back-button__icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $accent;
  margin-left: 16px;
  transition: $transitionDefault;

  .icon {
    fill: #fff;
    margin: 13px 0 0 7px;
  }
}

.navigation-ordered {
  font-family: $sansFont;
  @include fs(12);
  text-transform: uppercase;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  letter-spacing: 2px;
  counter-reset: counter;

  ol {
    margin: -20px 0 0;
  }

  li {
    border-bottom: 1px solid #c6c6c6;
    margin: 0;

    &:before {
      display: none;
    }

    &:last-child {
      border-bottom: 0;

      a {
        padding-bottom: 0;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
    padding: 20px 20px 20px 0;
    color: #a6a6a6;
    line-height: 1.4em;
    word-wrap: break-word;

    span {
      word-wrap: break-word;
      display: block;
    }

    &:before {
      content: counter(ol-counter)'.';
      counter-increment: ol-counter;
      display: inline-block;
      margin-right: 4px;
    }

    &:after {
      content: "»"; display: block;
      position: absolute; top: 18px; right: 5px;
      color: $accent;
      transition: $transitionDefault;
      @include fs(16);
    }

    &:hover, &:focus {
      &:after {
        transform: translateX(5px);
      }
    }
  }

  li.active a, a:hover, a:focus {
    color: $accent;
  }

  li.active a {
    padding-left: 10px;
  }

  @media #{$huge} {
    @include fs(14);

    a {
      padding-right: 45px;

      &:after {
        right: 20px;
        @include fs(18);
      }
    }

    li.active a {
      padding-left: 20px;
    }
  }
}


/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  margin-top: -35px;
  margin-right: 15px;
  pointer-events: none;
  z-index: 2;

  .grid {
    margin-bottom: 0;
  }
}

a.esign {
  width: 80px; height: 20px;
  background-image: url("../images/logo-esign.svg"); background-size: 77px 20px; background-repeat: no-repeat; background-position: top left;
  backface-visibility: hidden; transform: translate3d(0,0,0); text-indent: -9999px;
  opacity: 0.6; transition-duration: 0.5s;
  display: block; outline: 0; z-index: 9999;
  float: right;

  &:hover, &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 16px;
    float: right;
    pointer-events: auto;

    &:hover, &:focus {
      opacity:1; width: 80px;
    }
  }

  @media #{$huge} {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}


/* Age check */
.age-check-overlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(20, 10, 13, 0.85);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 30px;
}

.age-check {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: #fff url('../images/age-check-bg.jpg') bottom right no-repeat;
  max-width: 850px;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: $sansFont;
  color: #949494;

  &:before {
    position: absolute;
    content: ""; display: block;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    border: 14px solid transparent;
    border-image: url('../images/border.svg') 20 round;
  }

  @media #{$medium} {
    &:before {
      top: -30px;
      right: -30px;
      bottom: -30px;
      left: -30px;
    }
  }
}

.age-check__container {
  position: relative;
  margin: 0 auto;
  max-width: 630px;
  padding-top: 15px;

  @media #{$medium} {
    padding-top: 60px;
    padding-bottom: 35px;
  }
}

.age-check__title {
  @extend .h1;
}

.age-check__buttons {
  margin: 0 0 35px;
  border-bottom: 1px solid $accent;
  @include clearfix;
}

.age-check__no,
.age-check__yes {
  position: relative;
  width: 50%;
  float: left;
  text-align: center;
  text-transform: uppercase;
  height: 75px;
  line-height: 75px;
  @include fs(12);
  margin: 0;
  background: transparent;

  &:active {
    box-shadow: none;
  }

  svg {
    margin-bottom: 4px;
  }
}

.age-check__no {
  svg {
    transform: rotate(180deg);
    margin-right: 15px;
  }

  &:hover, &:focus {
    svg {
      transform: rotate(180deg) translateX(10px);
    }
  }
}

.age-check__yes {
  border-left: 1px solid $accent;

  svg {
    margin-left: 15px;
  }

  &:hover, &:focus {
    svg {
      transform: translateX(10px);
    }
  }
}

.age-check__info {
  margin: 0 10px;
  @include fs(14);

  a {
    color: #949494;

    &:hover, &:focus {
      color: $accent;
    }
  }

  @media #{$medium} {
    margin: 0 50px;
    @include fs(16);
  }
}

.age-check__message {
  color: #000;
  margin-bottom: 20px;
}



/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.rte {
  @include clearfix;
  margin-bottom: 20px;

  em {
    color: $accent;
    font-style: normal;
  }

  p {
    text-indent: 10px;
  }

  p, ul, ol {
    + h2, + h3 {
      margin-top: 3.5em;
    }
  }

  h2, h3 {
    text-transform: uppercase;
    font-family: $sansFont;
    @include fs(14);
    color: #a6a6a6;
    font-weight: 600;
    margin-bottom: 1.6em;
    letter-spacing: 1px;
  }

  h3 {
    text-transform: none;
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        content: "»"; color: $accent;
        display: block;
        position: absolute; top: -1px; left: -20px;
      }
    }
  }

  @media #{$medium} {
    @include fs(21);

    p {
      text-indent: 20px;
    }


    + dl {
      margin-top: 45px;
    }
  }
}

.item-description-list {
  @media #{$maxMedium} {
    border-top: none;
    dd, dt {
      padding: 10px 0;

      width: 50%;
      float: left;
      border-top: 1px solid $gray;
      &:after {
        content: '';
        display: block;
        clear: both;
      }

    }

   /* dd {
      padding: 5px;
    }*/

  }
}

.rte--small {
  @include fs(18);
}

.rte--sans {
  font-family: $sansFont;
}

.rte--compact {
  p {
    margin-bottom: 0.8em;
  }
}

.rte--first-letter {
  p:first-of-type {
    text-indent: 0;

    &:first-letter {
      font-weight: 600;
      @include fluid-type(font-size, $mediumSize, $largerSize, 58px, 72px);
      float: left;
      margin: 18px 18px 0 0;
    }
  }
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none; display: inline-block;
  }
}


// Article
section { position: relative; }

article {
  @include clearfix; margin: 0 0 20px;
}

.article-detail {

}

.article-detail__image {
  margin-bottom: 1.5em;
}


// Note
.note {
  @include clearfix;
  margin: 0 0 20px; padding: 15px 20px 0;
  background: #efefef;
  @include fs(16);

  ul, p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}


// Slideshow
.cycle-slideshow {
  width: 100%; position: relative;
}

.cycle-pager {
  width: 100%; text-align: center;

  span {
    display: inline-block; margin: 0 3px; font-size: 36px;
    height: 20px; width: 20px; line-height: 14px;
    cursor:pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden; text-indent: -500px;
    transition: $transitionDefault;

    &:hover, &:focus {
      transition: none;
    }
  }

  span:hover, span:focus, .cycle-pager-active {
    text-indent: 0;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px; width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social-grid {
  margin: 20px 0 0;


  .grid__item:nth-last-child(-n+2) {
    display: none;
  }

  @media #{$medium} {
    margin-top: 70px;

  }

  @media #{$huge} {
    .grid__item:nth-last-child(-n+2) {
      display: block;
    }
  }
}

.social-grid__item {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  text-align: center;

  a {
    text-decoration: none;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $accent;

    &:hover, &:focus {
      opacity: 0.8;

      .icon {
        transform: translateY(-2px) scale(1.05);
      }
    }
  }
}

.social-grid__item-content {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;

  img {
    width: 100%;
  }

  @supports (object-fit: cover) {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.social-grid__item--follow {
  overflow: visible;

  .social-grid__item-content {
    position: absolute; z-index: 100;
    top: -25px; left: -25px; right: -25px; bottom: -25px;
    background-color: #fff;
    box-shadow: 0px 35px 60px rgba(50, 7, 7, 0.15);
  }

  a {
    &:hover, &:focus {
      opacity: 1;
    }
  }
}

.social-grid__item-text {
  text-transform: uppercase;
  max-width: 140px;
  line-height: 1.7em;
  letter-spacing: 1px;
  font-family: $sansFont;
  margin: $gutter*2 auto 0;
  a { text-decoration: none; }
  @include fs(14);
  transition: $transitionDefault;

  .icon {
    display: block;
    position: relative;
    margin: 0 auto 18px;
  }
}


// Hero
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-bottom: 20px;



  @media #{$medium} {
    margin-bottom: 90px;
  }
}

.hero__visual {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: -1;

  img {
    width: 100%;
  }

  @supports (object-fit: cover) {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.hero--home {


  .hero__content {
    @media #{$medium} {
      min-height: 100vh;
    }
  }
}

body.flex-edge-ie-fix {
  .hero--home {

    @media #{$headerHugeMinHeight} {
      margin-bottom: 175px;

    }
    .hero__content {
      @media #{$medium} {
        min-height: 100vh;
      }
    }
  }
}

.hero__content {

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: #fff;
  padding: 0 30px;
  margin-bottom: 20px;

/*  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);*/



  h1 {
    color: #fff;
  }

  @media #{$medium} {
    margin-bottom: 50px;

  }
}

.hero__logo {
  margin-bottom: 50px;

  @media #{$medium} {
    margin-bottom: 90px;
  }
}

.hero--history {
  z-index: 100;
  background-color: #eeeeee;

  .hero__content {
    color: $black;

    h1 {
      color: $accent;
      @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 68px);
      margin-bottom: 0.15em;
    }
  }

  @media #{$medium} {
    @include fs(24);


    .hero__content {
      max-height: 50vh;
      min-height: 50vh;
    }
  }

  @media #{$large} {
    .icon-scroll-down {
      position: relative;
      top: 6vh;
    }
  }
}

.hero__visual--history {
  top: 30vh;
  height: 100%;
  max-height: 80vh;
  bottom: inherit;

  @media #{$medium} {
    top: 45vh;
  }
}

.hero___arrow-down {
  display: block;
  text-decoration: none;
  width: 41px; height: 41px;
  border: 1px solid $accent;
  position: absolute; bottom: 75px; left: 50%;
  transform: translateX(-50%);

  .hero___arrow-down-icon {
    transform: rotate(90deg);
    margin-left: 2px;
  }
  &:hover, &:focus, &:active {
    text-decoration: none;

    .hero___arrow-down-icon {
      transform: rotate(90deg) translateX(5px);
    }


  }

  @media #{$medium} {
    bottom: 40px;
    width: 51px; height: 51px;
  }

  @media (min-height: 700px) and (min-width: 1024px) {
    bottom: 14vh;
  }
}

.hero___arrow-down-icon {
  font-style: normal;
  transform: rotate(90deg) translateY(-3px);
  text-align: center;
  display: block;
  transition: $transitionDefault;

  &:before {
    content: "»";
    color: #fff;
    @include fs(22);
    line-height: 41px;
  }

  @media #{$medium} {
    &:before {
      @include fs(28);
      line-height: 51px;
    }
  }
}

// Section header
.section-header {
  text-align: center;

  @media #{$medium} {
    @include fs(21);
  }
}

.section-header__title {
  @extend .h1;
  margin-bottom: 0.3em;
}

.section-header--news {
  margin: 30px 0;

  @media #{$large} {
    margin: 65px 0;
  }

  @media #{$massive} {
    margin-top: 110px;
    margin-bottom: 100px;
  }
}


// Thumbs list
.thumbs-list {
  @include clearfix;
  list-style: none;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  margin: 40px auto;
  max-width: 1140px;

  li {
    width: 50%;
    display: block;
    float: left;
    margin-bottom: 30px;
  }

  @media #{$medium} {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 90px;
    margin-bottom: 150px;

    li {
      width: 33.33%;
      margin-bottom: 0;
    }
  }

  @media #{$large} {
    li {
      width: 16.66%;
    }
  }
}

.thumbs-list--home {
  @media #{$medium} {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    li {
      margin-bottom: 30px;
    }
  }

  @media #{$large} {
    li {
      margin-bottom: 0;
    }
  }
}

.thumbs-list__link {
  display: block;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  @include fs(16);
  color: #a5a5a5;
  position: relative;

  &:hover, &:focus {
    color: $accent;

    .thumbs-list__visual {
      img {
        transform: translateY(-15px);
      }

      &:before {
        opacity: 0.25;
        transform: scale(1) translateY(-10px);
      }

      &:after {
        opacity: 0.4;
        transform: scale(0.9);
      }
    }

    .thumbs-list__overlay {
      opacity: 1;
      transform: translateY(0);
    }

    .thumbs-list__text {
      opacity: 0;
    }
  }
}

.thumbs-list__visual {
  position: relative;
  margin-bottom: 25px;

  img, &:before, &:after {
    transition: $transitionDefault;
  }

  &:before, &:after {
    content: ""; display: block; position: absolute; left: 50%;
    z-index: -1;
  }

  &:before {
    top: 35px; margin-left: -70px;
    width: 140px; height: 200px;
    background: url('../images/logo-icon-brown.svg') center center no-repeat;
    background-size: contain;
    opacity: 0;
    transform: scale(1.05);
  }

  &:after {
    bottom: -25px; margin-left: -72px;
    width: 140px; height: 60px;
    background: url('../images/product-shadow.png') center center no-repeat;
    background-size: contain;
    opacity: 0.8;
  }

  img {
    max-height: 180px;
  }

  @media #{$medium} {
    img {
      max-height: 260px;
    }
  }
}

.thumbs-list__text {
  transition: $transitionDefault;
}

.thumbs-list__text--label {
  @media #{$medium} {
    position: absolute;
    top: 50%;
    right: 50px;
    width: auto;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    color: $accent;
    background-color: rgba(#fff, 0.95);
    padding: 5px 10px;
    margin-top: -12px;
  }

  @media #{$medium} and (min-height: 950px) {
    right: 60px;
  }
}

.thumbs-list__overlay {
  position: absolute;
  bottom: -45px;
  left: 10px;
  right: 10px;
  transition: $transitionDefault;
  opacity: 0;
  color: $accent;
  transform: translateY(10px);
  line-height: 1.3em;

  .icon {
    display: block;
    position: relative;
    margin: 13px auto 0;
  }
}

// Fixed thumbs list
.thumbs-list-title {
  text-align: center;
  margin-top: 40px;
  @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 40px);

  @media #{$medium} {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.thumbs-list--fixed {
  .active {
    .thumbs-list__link {
      opacity: 1;

      .thumbs-list__visual {
        &:before {
          opacity: 0.5;
        }
      }
    }

    .thumbs-list__text--label {
      opacity: 0;
    }
  }

  @media #{$maxMedium} {
    .active {
      display: none;
    }
  }

  @media #{$medium} {
    position: fixed;
    top: 50%;
    right: 30px;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);
    margin-top: 50px;
    z-index: 800;

    li {
      float: none;
      width: 100%;
      max-width: 60px;
    }

    img {
      max-height: 70px;
    }

    .thumbs-list__visual {
      margin-bottom: 16px;

      &:before {
        width: 40px;
        height: 60px;
        margin-left: -20px;
        top: 5px;
        opacity: 0;
      }

      &:after {
        display: none;
      }
    }

    .thumbs-list__link {
      backface-visibility: hidden;
      opacity: 0.5;

      &:hover, &:focus {
        opacity: 1;
        .thumbs-list__text--label {
          opacity: 1;
        }

        .thumbs-list__visual {
          &:before {
            transform: scale(1);
          }

          img {
            transform: translateY(0);
          }
        }
      }
    }
  }

  @media #{$medium} and (min-height: 950px) {
    margin-top: 50px;

    img {
      max-height: 100px;
    }

    .thumbs-list__visual {

      &:before {
        width: 58px;
        height: 85px;
        margin-left: -30px;
        top: 10px;
      }
    }
  }

  @media #{$medium} and (min-height: 1000px) {
    margin-top: 16px;
  }
}


// Cta list
.cta-list {
  margin: 0;

  .grid__item {
    padding-left: 0;
    border-bottom: 1px solid $accentLight;
  }

  @media #{$medium} {
    margin-right: -1px;
    margin-bottom: -1px;

    .grid__item {
      border-right: 1px solid $accentLight;
    }
  }
}

.cta {
  position: relative;
  text-decoration: none;
  display: block;
  text-align: center;
  color: $accent;
  line-height: 1.5em;

  &:hover, &:focus {
    color: $accent;

    .cta__visual img,
    .cta__visual--product:before {
      opacity: 0.3;
    }

    .cta__visual img {
      filter: blur(10px);
    }

    .cta__visual--product:before {
      transform: scale(0.9) translateY(40px);
    }

    .cta__text {
      opacity: 0;
      transform: translateY(10px);
    }

    .cta__overlay {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }

    .cta__overlay-text {
      transform: translateY(0px);
    }

    .icon {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  @media #{$medium} {
    @include fs(21);
  }
}

.cta--product {
  @media #{$medium} {
    .cta__content {
      padding-top: 50px;
    }

    .cta__text {
      min-height: 65px;
    }
  }

  @media #{$large} {
    .cta__content {
      padding-top: 90px;
    }
  }
}

.cta__content {
  padding: 30px;
}

.cta__visual-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 109%;
  margin-bottom: 30px;
}

.cta__visual {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;

  img {
    display: inline-block;
    transition: $transitionDefault;
    backface-visibility: hidden;
  }
}

.cta__visual--product {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &:before {
    content: ""; display: block; position: absolute;
    bottom: -35px; left: 50%; margin-left: -95px; z-index: -1;
    width: 189px; height: 82px;
    background: url('../images/product-shadow.png') center center no-repeat;
    transition: $transitionDefault;
    opacity: 0.8;
  }

  img {
    height: 100%;
    max-height: 390px;
  }
}

.cta__visual--slashed {
  overflow: hidden;

  img {
    width: 100%;
  }

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 44px;
    height: 20px;
    transform: rotate(-25deg);
    background-color: $bodyBg;
    z-index: 100;
  }

  &:before {
    top: -10px;
    left: -10px;
  }

  &:after {
    bottom: -10px;
    right: -10px;
  }
}

.cta__text {
  transition: $transitionDefault;

  @media #{$medium} {
    min-height: 75px;
  }
}

.cta__overlay {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(255, 255, 255, 0.082);
  opacity: 0; z-index: 200;
  transition: $transitionDefault;
  transform: scale(0.98);
  backface-visibility: hidden;
  visibility: hidden;
}

.cta__overlay-content {
  position: absolute;
  top: 15px; left: 15px; right: 15px; bottom: 15px;
  //border: 22px solid transparent;
  border-width: 22px;
  border-style: solid;
  border-image: url('../images/border.svg') 22 22 round;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    display: block;
    position: relative;
    margin: 13px auto 0;
    transform: translateX(-15px);
    opacity: 0;
  }
}

.cta__overlay-text {
  text-transform: uppercase;
  max-width: 136px;
  @include fs(16);
  font-family: $sansFont;
  transition: $transitionDefault;
  transform: translateY(20px);
}



// Item header
.item-header {
  @include clearfix;
  margin-bottom: 30px;

  h2 {
    @extend .h1;
  }
}

.item-header--lined {
  border-bottom: 1px solid $accent;
  margin-bottom: 50px;
}

.item-header__title {
  margin-bottom: 6px;
}

.item-header__subtitle {
  color: $gray;
  text-transform: uppercase;
  font-family: $sansFont;
  @include fs(14);
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  margin-bottom: 0;

  .divider {
    display: inline-block;
    margin: 0 12px;
  }
}


// Item footer
.item-footer {
  margin: 30px 0 10px;

  .button {
    margin: 0;
  }
}


// Lined list
.lined-list {
  list-style: none;
  margin: 0 0 3em;
  font-family: $sansFont;

  li {
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0;
  }

  @media #{$medium} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: calc(50% - 13px);
    }
  }

  @media #{$huge} {
    li {
      padding: 20px 0;
    }
  }
}



/* =============================================================================
   PAGES
   ========================================================================== */

/* History */
.history-section {
  position: relative;

  p {
    text-indent: 0;
  }

  + .history-section {
    border-top: 1px solid #e8e8e8;
    padding-top: 50px;
    margin-top: 60px;
  }

  @media #{$medium} {
    &:first-of-type {
      .history-section__title {
        color: #fff;
      }
    }
  }
}

.history-section--first-letter {
  @extend .rte--first-letter;
}

h2.history-section__title {
  @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 54px);
  font-family: $defaultFont;
  color: rgba($black, 0.35);
  text-transform: none;
  margin: 0 0 0.5em;
  font-weight: 400;

  &:before {
    content: ""; display: inline-block;
    width: 50px;
    border-top: 1px solid $accent;
    vertical-align: middle;
    margin-right: 30px;
  }

  @media #{$medium} {
    position: absolute;
    top: 50%; right: -335px;
    transform: translateY(-50%);
    width: 310px;
  }

  @media #{$massive} {
    right: -360px;

    &:before {
      width: 100px;
    }
  }
}


/* News */
.articles-list {
  @media #{$medium} {

    .grid__item {
      &:nth-child(3n+4) {
        clear: left;
      }
    }


  }

  @media #{$larger} {
    margin-left: -50px;

    .grid__item {
      padding-left: 50px;
    }
  }

  @media #{$huge} {
    margin-left: -75px;

    .grid__item {
      padding-left: 75px;
    }
  }
}

.article-contained {
  margin-bottom: 20px;

  @media #{$larger} {
    margin-bottom: 55px;
  }
}

.article-contained--has-visual {
  .article-contained__content {
    &:before {
      display: none;
    }
  }
}

.article-contained__visual {
  display: block;
  position: relative;
  z-index: 5;

  a {
    display: block;

    &:hover, &:focus {
      transform: scale(1.08);
      box-shadow: 0 0 55px rgba(193, 180, 180, 0.8);
    }
  }
}

.article-contained__content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;

  &:before, &:after {
    content: ""; display: block;
    position: absolute;
    width: 70px; height: 25px;
    background-color: #efefef;
    transform: rotate(-24deg);
  }

  &:before {
    top: -18px; left: -18px;
  }

  &:after {
    bottom: -18px; right: -18px;
  }

  @media #{$large} {
    padding: 30px;
  }

  @media #{$huge} {
    padding: 50px;
  }
}

.article-contained__date {
  text-transform: uppercase;
  font-family: $sansFont;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  color: #9f9f9f;
  @include fs(12);
  margin-bottom: 5px;
}

.article-contained__body {
  margin-bottom: 1em;
}

.article-contained__footer {
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: $sansFont;
  @include fs(14);

  .icon {
    margin-right: 8px;
  }

  a {
    text-decoration: none;
    color: #9f9f9f;

    &:hover, &:focus {
      color: $accent;
    }
  }
}

.pagination {
  p {
    @include clearfix;
  }
}

.pagination-pager {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  float: left;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  line-height: 1em;
  border: 1px solid #ddd;
  margin-right: -1px;
}



/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page { margin: 2cm 0.5cm 1cm; }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0; overflow: visible; direction: inherit;
  }

  a, a:visited {
    text-decoration: underline;
  }

  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }

  .page-footer, .main-nav { display: none; }

  .medium--one-half { width: 50%; }
  .medium--two-thirds { width: 66.66%; }
  .medium--one-third { width: 33.33%; }
  .medium--three-quarters { width: 75%; }
  .medium--one-quarter { width: 25%; }
}
