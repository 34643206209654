@charset "UTF-8";
/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block; }

*, *:before, *:after {
  box-sizing: border-box; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none; }

a:focus {
  outline: thin dotted; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

figure {
  margin: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

abbr {
  text-transform: uppercase; }

time {
  display: block; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0; } }

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    content: " "; }
  .clearfix:after {
    clear: both; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.hidden {
  display: none !important;
  visibility: hidden; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.vh.focusable:active, .vh.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full {
  *zoom: 1;
  margin: 0 0 20px -20px; }
  .grid:before, .grid--rev:before, .grid--full:before, .grid:after, .grid--rev:after, .grid--full:after {
    display: table;
    content: " "; }
  .grid:after, .grid--rev:after, .grid--full:after {
    clear: both; }

ul.grid, ul.grid--rev, ul.grid--full,
ul.grid-uniform {
  list-style: none;
  padding: 0; }

.grid__item {
  float: left;
  padding-left: 20px;
  width: 100%;
  margin-bottom: 0; }

.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

.grid--full {
  margin-left: 0; }
  .grid--full > .grid__item {
    padding-left: 0; }

.grid--lined {
  overflow: hidden;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 40px; }
  .grid--lined:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    border-bottom: 1px solid #c6c6c6; }

.one-whole {
  width: 100%; }

.one-half {
  width: 50%; }

.one-third {
  width: 33.333%; }

.two-thirds {
  width: 66.666%; }

.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

.one-sixth {
  width: 16.666%; }

.two-sixths {
  width: 33.333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.666%; }

.five-sixths {
  width: 83.333%; }

.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.one-twelfth {
  width: 8.333%; }

.two-twelfths {
  width: 16.666%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.333%; }

.five-twelfths {
  width: 41.666%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.333%; }

.eight-twelfths {
  width: 66.666%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.333%; }

.eleven-twelfths {
  width: 91.666%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%; }
  .medium--one-half {
    width: 50%; }
  .medium--one-third {
    width: 33.333%; }
  .medium--two-thirds {
    width: 66.666%; }
  .medium--one-quarter {
    width: 25%; }
  .medium--two-quarters {
    width: 50%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-fifth {
    width: 20%; }
  .medium--two-fifths {
    width: 40%; }
  .medium--three-fifths {
    width: 60%; }
  .medium--four-fifths {
    width: 80%; }
  .medium--one-sixth {
    width: 16.666%; }
  .medium--two-sixths {
    width: 33.333%; }
  .medium--three-sixths {
    width: 50%; }
  .medium--four-sixths {
    width: 66.666%; }
  .medium--five-sixths {
    width: 83.333%; }
  .medium--one-eighth {
    width: 12.5%; }
  .medium--two-eighths {
    width: 25%; }
  .medium--three-eighths {
    width: 37.5%; }
  .medium--four-eighths {
    width: 50%; }
  .medium--five-eighths {
    width: 62.5%; }
  .medium--six-eighths {
    width: 75%; }
  .medium--seven-eighths {
    width: 87.5%; }
  .medium--one-tenth {
    width: 10%; }
  .medium--two-tenths {
    width: 20%; }
  .medium--three-tenths {
    width: 30%; }
  .medium--four-tenths {
    width: 40%; }
  .medium--five-tenths {
    width: 50%; }
  .medium--six-tenths {
    width: 60%; }
  .medium--seven-tenths {
    width: 70%; }
  .medium--eight-tenths {
    width: 80%; }
  .medium--nine-tenths {
    width: 90%; }
  .medium--one-twelfth {
    width: 8.333%; }
  .medium--two-twelfths {
    width: 16.666%; }
  .medium--three-twelfths {
    width: 25%; }
  .medium--four-twelfths {
    width: 33.333%; }
  .medium--five-twelfths {
    width: 41.666%; }
  .medium--six-twelfths {
    width: 50%; }
  .medium--seven-twelfths {
    width: 58.333%; }
  .medium--eight-twelfths {
    width: 66.666%; }
  .medium--nine-twelfths {
    width: 75%; }
  .medium--ten-twelfths {
    width: 83.333%; }
  .medium--eleven-twelfths {
    width: 91.666%; }
  .medium--show {
    display: block !important; }
  .medium--hide {
    display: none !important; }
  .medium--text-left {
    text-align: left !important; }
  .medium--text-right {
    text-align: right !important; }
  .medium--text-center {
    text-align: center !important; }
  .medium--left {
    float: left !important; }
  .medium--right {
    float: right !important; } }

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%; }
  .large--one-half {
    width: 50%; }
  .large--one-third {
    width: 33.333%; }
  .large--two-thirds {
    width: 66.666%; }
  .large--one-quarter {
    width: 25%; }
  .large--two-quarters {
    width: 50%; }
  .large--three-quarters {
    width: 75%; }
  .large--one-fifth {
    width: 20%; }
  .large--two-fifths {
    width: 40%; }
  .large--three-fifths {
    width: 60%; }
  .large--four-fifths {
    width: 80%; }
  .large--one-sixth {
    width: 16.666%; }
  .large--two-sixths {
    width: 33.333%; }
  .large--three-sixths {
    width: 50%; }
  .large--four-sixths {
    width: 66.666%; }
  .large--five-sixths {
    width: 83.333%; }
  .large--one-eighth {
    width: 12.5%; }
  .large--two-eighths {
    width: 25%; }
  .large--three-eighths {
    width: 37.5%; }
  .large--four-eighths {
    width: 50%; }
  .large--five-eighths {
    width: 62.5%; }
  .large--six-eighths {
    width: 75%; }
  .large--seven-eighths {
    width: 87.5%; }
  .large--one-tenth {
    width: 10%; }
  .large--two-tenths {
    width: 20%; }
  .large--three-tenths {
    width: 30%; }
  .large--four-tenths {
    width: 40%; }
  .large--five-tenths {
    width: 50%; }
  .large--six-tenths {
    width: 60%; }
  .large--seven-tenths {
    width: 70%; }
  .large--eight-tenths {
    width: 80%; }
  .large--nine-tenths {
    width: 90%; }
  .large--one-twelfth {
    width: 8.333%; }
  .large--two-twelfths {
    width: 16.666%; }
  .large--three-twelfths {
    width: 25%; }
  .large--four-twelfths {
    width: 33.333%; }
  .large--five-twelfths {
    width: 41.666%; }
  .large--six-twelfths {
    width: 50%; }
  .large--seven-twelfths {
    width: 58.333%; }
  .large--eight-twelfths {
    width: 66.666%; }
  .large--nine-twelfths {
    width: 75%; }
  .large--ten-twelfths {
    width: 83.333%; }
  .large--eleven-twelfths {
    width: 91.666%; }
  .large--show {
    display: block !important; }
  .large--hide {
    display: none !important; }
  .large--text-left {
    text-align: left !important; }
  .large--text-right {
    text-align: right !important; }
  .large--text-center {
    text-align: center !important; }
  .large--left {
    float: left !important; }
  .large--right {
    float: right !important; } }

@media only screen and (min-width: 1400px) {
  .huge--one-whole {
    width: 100%; }
  .huge--one-half {
    width: 50%; }
  .huge--one-third {
    width: 33.333%; }
  .huge--two-thirds {
    width: 66.666%; }
  .huge--one-quarter {
    width: 25%; }
  .huge--two-quarters {
    width: 50%; }
  .huge--three-quarters {
    width: 75%; }
  .huge--one-fifth {
    width: 20%; }
  .huge--two-fifths {
    width: 40%; }
  .huge--three-fifths {
    width: 60%; }
  .huge--four-fifths {
    width: 80%; }
  .huge--one-sixth {
    width: 16.666%; }
  .huge--two-sixths {
    width: 33.333%; }
  .huge--three-sixths {
    width: 50%; }
  .huge--four-sixths {
    width: 66.666%; }
  .huge--five-sixths {
    width: 83.333%; }
  .huge--one-eighth {
    width: 12.5%; }
  .huge--two-eighths {
    width: 25%; }
  .huge--three-eighths {
    width: 37.5%; }
  .huge--four-eighths {
    width: 50%; }
  .huge--five-eighths {
    width: 62.5%; }
  .huge--six-eighths {
    width: 75%; }
  .huge--seven-eighths {
    width: 87.5%; }
  .huge--one-tenth {
    width: 10%; }
  .huge--two-tenths {
    width: 20%; }
  .huge--three-tenths {
    width: 30%; }
  .huge--four-tenths {
    width: 40%; }
  .huge--five-tenths {
    width: 50%; }
  .huge--six-tenths {
    width: 60%; }
  .huge--seven-tenths {
    width: 70%; }
  .huge--eight-tenths {
    width: 80%; }
  .huge--nine-tenths {
    width: 90%; }
  .huge--one-twelfth {
    width: 8.333%; }
  .huge--two-twelfths {
    width: 16.666%; }
  .huge--three-twelfths {
    width: 25%; }
  .huge--four-twelfths {
    width: 33.333%; }
  .huge--five-twelfths {
    width: 41.666%; }
  .huge--six-twelfths {
    width: 50%; }
  .huge--seven-twelfths {
    width: 58.333%; }
  .huge--eight-twelfths {
    width: 66.666%; }
  .huge--nine-twelfths {
    width: 75%; }
  .huge--ten-twelfths {
    width: 83.333%; }
  .huge--eleven-twelfths {
    width: 91.666%; }
  .huge--show {
    display: block !important; }
  .huge--hide {
    display: none !important; }
  .huge--text-left {
    text-align: left !important; }
  .huge--text-right {
    text-align: right !important; }
  .huge--text-center {
    text-align: center !important; }
  .huge--left {
    float: left !important; }
  .huge--right {
    float: right !important; } }

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
p {
  line-height: 1.7em;
  margin-bottom: 1.5em; }

strong {
  font-weight: 600;
  font-size: inherit; }

em {
  font-style: italic;
  font-weight: inherit; }

a {
  font-weight: inherit;
  font-style: inherit;
  color: #b1743e;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: underline;
  text-decoration-skip: ink; }
  a:hover, a:focus {
    color: #8b5b31; }
  a:focus, a:active {
    outline: 0; }

.tdn {
  text-decoration: none; }

.tdn--invisible {
  color: #404040; }
  .tdn--invisible:hover, .tdn--invisible:focus {
    color: #b1743e; }

a[href^=tel] {
  text-decoration: none; }
  a[href^=tel]:hover, a[href^=tel]:focus {
    text-decoration: underline; }

a:not([href]) {
  color: #404040;
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle; }

.accent {
  color: #b1743e; }

h1, .h1, .age-check__title, .section-header__title, .item-header h2, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #b1743e;
  line-height: 1.2em;
  margin: 0 0 0.8em; }
  h1 a, .h1 a, .age-check__title a, .section-header__title a, .item-header h2 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: #b1743e; }
    h1 a:hover, h1 a:focus, .h1 a:hover, .age-check__title a:hover, .section-header__title a:hover, .item-header h2 a:hover, .h1 a:focus, .age-check__title a:focus, .section-header__title a:focus, .item-header h2 a:focus, h2 a:hover, h2 a:focus, .h2 a:hover, .h2 a:focus, h3 a:hover, h3 a:focus, .h3 a:hover, .h3 a:focus, h4 a:hover, h4 a:focus, .h4 a:hover, .h4 a:focus, h5 a:hover, h5 a:focus, .h5 a:hover, .h5 a:focus, h6 a:hover, h6 a:focus, .h6 a:hover, .h6 a:focus {
      color: #8b5b31; }

h1, .h1, .age-check__title, .section-header__title, .item-header h2, h2, .h2 {
  font-family: "poetica-std", "calluna", Times, Times New Roman, serif;
  line-height: 1em;
  font-feature-settings: "swsh" 1, "liga" 1, "clig" 0, "calt" 0, "kern"; }

h1, .h1, .age-check__title, .section-header__title, .item-header h2 {
  font-size: 42px; }
  @media screen and (min-width: 768px) {
    h1, .h1, .age-check__title, .section-header__title, .item-header h2 {
      font-size: calc(42px + 6 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h1, .h1, .age-check__title, .section-header__title, .item-header h2 {
      font-size: 48px; } }

h2, .h2 {
  font-size: 20px; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: calc(20px + 8 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 28px; } }

h3, .h3 {
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #a6a6a6;
  margin-top: 6px;
  line-height: 1.5em; }

h4, .h4 {
  font-size: 16px;
  font-size: 1.6rem; }

ul, ol {
  margin: 0 0 1.5em 20px; }
  ul li, ol li {
    line-height: 1.5em;
    margin-bottom: 8px;
    padding: 0; }

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0; }

ul {
  list-style: disc; }
  ul ul {
    list-style: circle; }

ol {
  list-style: none;
  counter-reset: ol-counter; }
  ol li {
    position: relative; }
    ol li:before {
      content: counter(ol-counter);
      counter-increment: ol-counter;
      color: #b1743e;
      display: block;
      position: absolute;
      top: -1px;
      left: -20px;
      font-feature-settings: "tnum";
      font-variant-numeric: lining-nums; }

.unstyled, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none; }
  .unstyled ul, nav ul ul {
    list-style: none; }
  .unstyled li, nav ul li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none; }

nav ul {
  *zoom: 1; }
  nav ul:before, nav ul:after {
    display: table;
    content: " "; }
  nav ul:after {
    clear: both; }

dl {
  margin: 0 0 1.5em;
  border-top: 1px solid #b1743e;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  display: flex;
  flex-wrap: wrap; }

dt, dd {
  line-height: 1.5em;
  padding: 20px 0; }
  dt + dt, dd + dt {
    border-top: 1px solid #a6a6a6; }
  @media (min-width: 768px) {
    dt, dd {
      float: left;
      padding: 15px 0; }
      dt + dd, dd + dd {
        border-top: 1px solid #a6a6a6; }
        dt + dd:first-of-type, dd + dd:first-of-type {
          border-top-color: transparent; } }

dt {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 1.4rem;
  color: #a6a6a6;
  letter-spacing: 1px; }
  @media (max-width: 766px) {
    dt {
      padding-bottom: 0; } }
  @media (min-width: 768px) {
    dt {
      width: 170px;
      padding-top: 20px; } }
  @media (min-width: 1400px) {
    dt {
      width: 195px; } }

dd {
  margin-bottom: 0.5em; }
  @media (max-width: 766px) {
    dd {
      padding-top: 10px; } }
  @media (min-width: 768px) {
    dd {
      width: calc(100% - 170px); } }
  @media (min-width: 1400px) {
    dd {
      width: calc(100% - 195px); } }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #a6a6a6;
  margin: 50px 0; }

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid #a6a6a6;
  margin-bottom: 1.5em;
  font-size: 20px;
  font-size: 2rem;
  font-style: italic; }
  blockquote p {
    margin-bottom: 0.5em; }
  blockquote cite {
    font-size: 14px;
    font-size: 1.4rem; }
  @media (min-width: 768px) {
    blockquote {
      padding-left: 40px; } }

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em; }
  table th, table td {
    padding: 11px 6px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    line-height: 1.5em; }
  table th {
    font-weight: bold;
    border-bottom: 2px solid #ddd; }

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px; }

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

label, .label, input, select, textarea {
  font-family: "calluna", Times, Times New Roman, serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.7em;
  font-weight: 400;
  vertical-align: middle;
  color: #404040; }

label, .label {
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  color: #9d9d9d;
  font-size: 12px;
  font-size: 1.2rem; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none; }

input[type="checkbox"], input[type="radio"] {
  cursor: pointer; }

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 20px;
  margin-bottom: 2px;
  display: block;
  background: #fff;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #320707; }
  input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
  input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, textarea:focus, select:focus {
    outline: 0;
    border-color: #b1743e; }

input[type="email"] {
  display: block; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 115px; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none; }

.checkbox {
  position: relative; }
  .checkbox a {
    text-decoration: none; }
  .checkbox label {
    text-transform: none;
    font-size: 18px;
    font-size: 1.8rem;
    font-family: "calluna", Times, Times New Roman, serif;
    color: #404040; }
  .checkbox input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
    .checkbox input[type="checkbox"]:checked + label:after {
      opacity: 1; }
  .checkbox label {
    cursor: pointer; }
    .checkbox label:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border: 1px solid #b1743e;
      background-color: #fff;
      margin-right: 12px;
      margin-top: -2px; }
    .checkbox label:after {
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 3px;
      width: 14px;
      height: 14px;
      background: url("../images/checkmark.svg") center center no-repeat;
      background-size: cover;
      z-index: 1;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
      opacity: 0; }

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 5px 26px;
  margin: 10px 0 0;
  background-color: transparent;
  text-decoration: none;
  color: #404040;
  text-transform: uppercase;
  border: 14px solid transparent;
  border-image: url("../images/border.svg") 21 round;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-align: center;
  font-variant-numeric: lining-nums; }
  input[type="reset"] .icon, input[type="submit"] .icon, .button .icon {
    fill: #404040;
    margin-left: 15px; }
  input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, .button:hover, .button:focus, .button:active {
    background-color: transparent; }
    input[type="reset"]:hover .icon, input[type="reset"]:focus .icon, input[type="reset"]:active .icon, input[type="submit"]:hover .icon, input[type="submit"]:focus .icon, input[type="submit"]:active .icon, .button:hover .icon, .button:focus .icon, .button:active .icon {
      transform: translateX(5px); }
  input[type="reset"]:active, input[type="submit"]:active, .button:active {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(1px); }
  input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
    border: 0; }
  @media (min-width: 1400px) {
    input[type="reset"] .icon, input[type="submit"] .icon, .button .icon {
      fill: #404040;
      margin-left: 35px; } }

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none; }

.button--full {
  width: 100%; }

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit;
  box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1); }

::placeholder {
  color: #999;
  font-style: italic; }

::-ms-clear {
  display: none; }

input[type="search"] {
  -webkit-appearance: none; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

::-webkit-inner-spin-button {
  -webkit-appearance: none; }

::-webkit-outer-spin-button {
  -webkit-appearance: none; }

form .row {
  margin-bottom: 0; }

fieldset {
  width: auto; }
  fieldset p, fieldset ul, fieldset li {
    *zoom: 1; }
    fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
      display: table;
      content: " "; }
    fieldset p:after, fieldset ul:after, fieldset li:after {
      clear: both; }
  fieldset ul {
    margin-left: -20px;
    margin-bottom: 0; }
    fieldset ul li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 25px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px; }
      fieldset ul li.error input {
        border-color: #f1062c; }
      fieldset ul li.space {
        margin-top: 25px; }
      @media (min-width: 768px) {
        fieldset ul li.one-half {
          width: 50%; }
        fieldset ul li.one-fourth {
          width: 25%; } }

.buttons {
  *zoom: 1; }
  .buttons:before, .buttons:after {
    display: table;
    content: " "; }
  .buttons:after {
    clear: both; }
  .buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
    float: left;
    clear: none;
    margin-right: 15px; }
    .buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
      float: right;
      margin-right: 0;
      margin-left: 15px; }

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block; }

.grecaptcha-badge {
  display: none; }

.formError {
  z-index: 990; }

.formError .formErrorContent {
  z-index: 991; }

.formError .formErrorArrow {
  z-index: 996; }

.formErrorInsideDialog.formError {
  z-index: 5000; }

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001; }

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006; }

.inputContainer {
  position: relative;
  float: left; }

.formError, .formError * {
  box-sizing: content-box; }

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -128px; }

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none; }

.formError .formErrorContent {
  background: #f1062c;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #f1062c;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif; }

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px; }
  .formError .formErrorArrow:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top-color: #f1062c; }

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px; }

.formError .formErrorArrow div {
  display: none; }

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%; }

body {
  font-family: "calluna", Times, Times New Roman, serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.7em;
  font-weight: 400;
  color: #404040;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #efefef; }

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }

.container--medium {
  max-width: 1380px; }

.wf-loading {
  overflow: hidden; }
  .wf-loading body {
    visibility: hidden; }

::selection {
  background-color: #fbf7f3;
  color: inherit; }

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  font-style: normal;
  fill: #b1743e; }

.icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid #b1743e;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.icon-hamburger {
  position: relative; }
  .icon-hamburger:before, .icon-hamburger:after {
    content: "";
    margin-left: 0; }
  .icon-hamburger:before {
    margin-top: 3px; }
  .icon-hamburger:after {
    margin-top: 9px; }

.icon-instagram {
  width: 30px;
  height: 30px; }

.icon-arrow-double {
  width: 22px;
  height: 9px; }

.icon-download {
  width: 14px;
  height: 18px; }

.icon-scroll-down {
  font-size: 30px;
  font-size: 3rem;
  color: #320707;
  font-style: normal; }
  .icon-scroll-down:before {
    content: "»";
    display: block;
    transform: rotate(90deg); }

.gm-style img {
  max-width: inherit; }

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap; }
  .equal-h > * {
    display: flex; }
  .equal-h:before, .equal-h:after {
    display: none; }

html.no-flexbox .equal-h {
  height: 100%; }
  html.no-flexbox .equal-h > div {
    float: left; }

.block-link {
  position: relative;
  cursor: pointer; }

.block-link__target:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  padding: 30px 20px 10px;
  background: #1a1a1a url("../images/logo-icon-black.svg") center center no-repeat;
  background-size: 600px 773px; }
  .page-header:before, .page-header:after {
    display: table;
    content: " "; }
  .page-header:after {
    clear: both; }
  @media (min-width: 1024px) {
    .page-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      min-height: 100vh;
      float: left;
      padding: 40px 30px 0; } }
  @media (min-width: 1400px) {
    .page-header {
      width: 390px;
      padding-left: 65px;
      padding-right: 65px; } }
  @media (min-height: 1020px) and (min-width: 1024px) {
    .page-header {
      background-size: 900px 1160px;
      padding-top: 60px; } }

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
  margin-bottom: 20px;
  display: block;
  max-width: 100%; }
  .page-header__logo img {
    display: block; }
  .page-header__logo:hover, .page-header__logo:focus {
    opacity: 0.8;
    backface-visibility: hidden; }
  @media (max-width: 1022px) {
    .page-header__logo {
      max-width: 180px; } }
  @media (min-width: 1024px) {
    .page-header__logo {
      border-bottom: 1px solid #848484;
      margin-bottom: 20px;
      float: left; }
      .page-header__logo img {
        margin-bottom: 30px; } }
  @media (min-height: 760px) and (min-width: 1024px) {
    .page-header__logo {
      margin-bottom: 25px; }
      .page-header__logo img {
        margin-bottom: 35px; } }
  @media (min-height: 1020px) and (min-width: 1024px) {
    .page-header__logo {
      margin-bottom: 40px; }
      .page-header__logo img {
        margin-bottom: 50px; } }

.main-nav-trigger {
  display: block;
  position: relative;
  padding: 12px 40px 12px 0;
  background-color: transparent;
  color: #404040;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 30px;
  right: 20px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-size: 21px;
  font-size: 2.1rem;
  color: #fff; }
  .main-nav-trigger .icon-hamburger, .main-nav-trigger .icon-hamburger:before, .main-nav-trigger .icon-hamburger:after {
    right: 0;
    left: inherit; }
  .main-nav-trigger .icon-hamburger {
    position: absolute;
    top: 18px; }
  .main-nav-trigger:hover, .main-nav-trigger:focus {
    background-color: transparent;
    box-shadow: none; }
  .main-nav-trigger:active {
    transform: none; }
  @media (min-width: 1024px) {
    .main-nav-trigger {
      display: none; } }

.main-nav-wrap {
  display: none;
  margin: 30px 0 0; }
  @media (max-width: 1022px) {
    .main-nav-wrap {
      width: calc(100vw - 40px);
      border-top: 1px solid #848484;
      padding-top: 10px; } }
  @media (min-width: 1024px) {
    .main-nav-wrap {
      display: block !important; } }

.main-nav {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-size: 21px;
  font-size: 2.1rem;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px; }
  .main-nav > ul {
    display: block; }
    .main-nav > ul > li {
      margin-bottom: 5px; }
      .main-nav > ul > li.active > a:before {
        content: "»";
        display: inline-block;
        margin-right: 12px;
        position: relative;
        top: -2px; }
  .main-nav a {
    text-decoration: none;
    color: #fff;
    padding: 9px 15px;
    display: inline-block; }
  .main-nav a:hover, .main-nav a:focus, .main-nav li.active > a {
    color: #b1743e; }
  @media (min-width: 1024px) {
    .main-nav {
      margin-bottom: 50px; }
      .main-nav > ul {
        margin: 0 0 0 -20px; } }
  @media (min-height: 900px) and (min-width: 1024px) {
    .main-nav > ul > li {
      margin-bottom: 15px; } }
  @media (min-height: 760px) and (min-width: 1024px) {
    .main-nav {
      margin-bottom: 20px; } }
  @media (min-height: 900px) and (min-width: 1024px) {
    .main-nav {
      margin-bottom: 50px; }
      .main-nav a {
        padding: 10px 15px; } }
  @media (min-height: 1020px) and (min-width: 1024px) {
    .main-nav {
      margin-bottom: 100px; } }

.main-nav__dropdown {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 0 30px;
  display: none; }
  .main-nav__dropdown li {
    margin-bottom: 3px; }
    .main-nav__dropdown li a {
      padding-left: 15px;
      position: relative;
      display: block; }
      .main-nav__dropdown li a:before {
        content: "›";
        display: block;
        position: absolute;
        top: 9px;
        left: 0; }
  @media (min-width: 1400px) {
    .main-nav__dropdown {
      margin-left: 40px; } }

.main-nav__dropdown-toggle {
  color: #b1743e;
  display: inline-block;
  padding: 0 5px;
  background-color: transparent;
  position: relative;
  top: 0;
  opacity: 0.8;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  width: 18px;
  height: 18px;
  border: 1px solid #b1743e; }
  .main-nav__dropdown-toggle .icon {
    margin: 0;
    position: relative;
    top: -4px;
    left: -1px; }
  .main-nav__dropdown-toggle[area-expanded="true"] .icon {
    left: 0; }
  .main-nav__dropdown-toggle:hover, .main-nav__dropdown-toggle:focus {
    opacity: 1;
    border-color: #fff; }
    .main-nav__dropdown-toggle:hover .icon, .main-nav__dropdown-toggle:focus .icon {
      color: #fff; }

@media (min-width: 1024px) and (min-height: 760px) and (min-width: 1024px) {
  .main-nav__dropdown {
    display: block; }
  .main-nav__dropdown-toggle {
    display: none; } }

.lang {
  border: 14px solid inherit;
  border-width: 14px;
  border-style: solid;
  border-image: url("../images/border.svg") 20 round;
  display: inline-block;
  margin-bottom: 10px; }
  @media (min-width: 1024px) {
    .lang {
      margin-bottom: 0;
      position: absolute;
      bottom: 30px;
      height: 50px; } }
  @media (min-width: 1400px) and (min-height: 900px) and (min-width: 1024px) {
    .lang {
      bottom: 65px; } }

.lang__list {
  *zoom: 1;
  list-style: none;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  margin: -14px; }
  .lang__list:before, .lang__list:after {
    display: table;
    content: " "; }
  .lang__list:after {
    clear: both; }
  .lang__list a {
    display: block;
    text-decoration: none;
    color: #b1743e;
    height: 50px;
    padding: 0 18px;
    line-height: 50px; }
  .lang__list li.active a, .lang__list a:hover, .lang__list a:focus {
    color: #fff; }
  .lang__list li {
    float: left;
    margin: 0; }
    .lang__list li + li a {
      border-left: 1px solid #b1743e; }

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap; }
  .skip-link:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    font-size: 20px;
    font-size: 2rem; }

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  overflow: hidden;
  *zoom: 1; }
  .main:before, .main:after {
    display: table;
    content: " "; }
  .main:after {
    clear: both; }
  @media (min-width: 1024px) {
    .main {
      padding-left: 250px;
      min-height: 100vh; } }
  @media (min-width: 1400px) {
    .main {
      padding-left: 390px; } }

.page-content {
  *zoom: 1;
  background-color: #fff;
  padding: 20px; }
  .page-content:before, .page-content:after {
    display: table;
    content: " "; }
  .page-content:after {
    clear: both; }
  @media (min-width: 768px) {
    .page-content {
      width: 50%;
      max-width: 720px;
      float: left; } }
  @media (min-width: 1024px) {
    .page-content {
      padding: 65px 50px 55px;
      min-height: 100vh; } }
  @media (min-width: 1200px) {
    .page-content {
      padding-top: 75px; } }
  @media (min-width: 1680px) {
    .page-content {
      padding-top: 105px;
      padding-left: 100px;
      padding-right: 100px; } }

.page-content--offset {
  position: relative; }
  @media (min-width: 768px) {
    .page-content--offset {
      z-index: 500;
      margin-top: -250px;
      padding-top: 50px; } }
  @media (min-width: 1680px) {
    .page-content--offset {
      padding-top: 90px;
      margin-top: -300px; } }

.page-aside {
  background: url("../images/page-aside-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 20px;
  min-height: 350px; }
  @media (max-width: 766px) {
    .page-aside + .page-content {
      padding-top: 30px; } }
  @media (min-width: 768px) {
    .page-aside {
      position: fixed;
      right: 0;
      top: 0;
      min-height: 100vh;
      width: 50%;
      padding: 30px 40px;
      z-index: 1; } }
  @media (min-width: 1024px) {
    .page-aside {
      width: calc(50% - 125px); } }
  @media (min-width: 1200px) {
    .page-aside {
      padding: 50px; } }
  @media (min-width: 1400px) {
    .page-aside {
      width: calc(50% - 195px); } }
  @media (min-width: 1830px) {
    .page-aside {
      left: 1110px;
      width: auto; } }
  @media (min-width: 1680px) {
    .page-aside {
      padding-left: 100px;
      padding-right: 100px; } }

.page-aside--overlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  z-index: -1;
  pointer-events: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
  /* IE6-9 */ }

@media (min-width: 768px) {
  .page-aside__content {
    max-width: 560px; } }

@media (min-width: 1024px) {
  .page-aside--align {
    padding-top: 75px; } }

@media (min-width: 1680px) {
  .page-aside--align {
    padding-top: 105px; } }

@media (min-width: 768px) {
  .page-aside--scrollable {
    position: absolute; } }

@media (min-width: 768px) {
  .page-aside--scrollable-bottom {
    position: fixed;
    bottom: 0;
    top: inherit; } }

.page-aside__jumbotron {
  margin: 20px;
  background-color: #fff;
  padding: 20px; }
  .page-aside__jumbotron:before {
    position: absolute;
    content: "";
    display: block;
    z-index: -1;
    top: 50px;
    left: 25px;
    right: 25px;
    bottom: 15px;
    background-color: #69341b;
    box-shadow: 0px 35px 120px rgba(105, 51, 24, 0.9); }
  @media (min-width: 768px) {
    .page-aside__jumbotron {
      padding: 40px;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      max-width: 620px; } }
  @media (min-width: 1400px) {
    .page-aside__jumbotron {
      padding: 65px;
      bottom: 40px;
      left: 40px;
      right: 40px; } }
  @media (min-width: 1680px) {
    .page-aside__jumbotron {
      bottom: 100px;
      left: 100px; } }

.page-aside__visual-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%; }

.page-aside__visual {
  max-height: 100%; }
  @media (min-width: 768px) {
    .page-aside__visual {
      padding-top: 40px;
      padding-right: 40px; }
      .page-aside__visual img {
        max-height: calc(100vh - 140px); } }
  @media (min-width: 1024px) {
    .page-aside__visual {
      padding-right: 80px;
      padding-left: 20px;
      padding-top: 20px; } }

.back-button {
  float: right;
  display: block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
  font-size: 1rem; }
  .back-button:hover, .back-button:focus {
    color: rgba(255, 255, 255, 0.8); }
    .back-button:hover .icon, .back-button:focus .icon {
      transform: translateX(2px); }

.back-button--dark {
  color: #404040; }
  .back-button--dark .back-button__icon .icon {
    fill: #404040; }
  .back-button--dark:hover, .back-button--dark:focus {
    color: rgba(0, 0, 0, 0.6); }

.back-button__icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b1743e;
  margin-left: 16px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .back-button__icon .icon {
    fill: #fff;
    margin: 13px 0 0 7px; }

.navigation-ordered {
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  letter-spacing: 2px;
  counter-reset: counter; }
  .navigation-ordered ol {
    margin: -20px 0 0; }
  .navigation-ordered li {
    border-bottom: 1px solid #c6c6c6;
    margin: 0; }
    .navigation-ordered li:before {
      display: none; }
    .navigation-ordered li:last-child {
      border-bottom: 0; }
      .navigation-ordered li:last-child a {
        padding-bottom: 0; }
  .navigation-ordered a {
    text-decoration: none;
    display: flex;
    padding: 20px 20px 20px 0;
    color: #a6a6a6;
    line-height: 1.4em;
    word-wrap: break-word; }
    .navigation-ordered a span {
      word-wrap: break-word;
      display: block; }
    .navigation-ordered a:before {
      content: counter(ol-counter) ".";
      counter-increment: ol-counter;
      display: inline-block;
      margin-right: 4px; }
    .navigation-ordered a:after {
      content: "»";
      display: block;
      position: absolute;
      top: 18px;
      right: 5px;
      color: #b1743e;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
      font-size: 16px;
      font-size: 1.6rem; }
    .navigation-ordered a:hover:after, .navigation-ordered a:focus:after {
      transform: translateX(5px); }
  .navigation-ordered li.active a, .navigation-ordered a:hover, .navigation-ordered a:focus {
    color: #b1743e; }
  .navigation-ordered li.active a {
    padding-left: 10px; }
  @media (min-width: 1400px) {
    .navigation-ordered {
      font-size: 14px;
      font-size: 1.4rem; }
      .navigation-ordered a {
        padding-right: 45px; }
        .navigation-ordered a:after {
          right: 20px;
          font-size: 18px;
          font-size: 1.8rem; }
      .navigation-ordered li.active a {
        padding-left: 20px; } }

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  margin-top: -35px;
  margin-right: 15px;
  pointer-events: none;
  z-index: 2; }
  .page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full {
    margin-bottom: 0; }

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  float: right; }
  a.esign:hover, a.esign:focus {
    opacity: 1; }
  @media (min-width: 768px) {
    a.esign {
      width: 16px;
      float: right;
      pointer-events: auto; }
      a.esign:hover, a.esign:focus {
        opacity: 1;
        width: 80px; } }
  @media (min-width: 1400px) {
    a.esign {
      margin-right: 20px;
      margin-bottom: 20px; } }

/* Age check */
.age-check-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 10, 13, 0.85);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 30px; }

.age-check {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: #fff url("../images/age-check-bg.jpg") bottom right no-repeat;
  max-width: 850px;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  color: #949494; }
  .age-check:before {
    position: absolute;
    content: "";
    display: block;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    border: 14px solid transparent;
    border-image: url("../images/border.svg") 20 round; }
  @media (min-width: 768px) {
    .age-check:before {
      top: -30px;
      right: -30px;
      bottom: -30px;
      left: -30px; } }

.age-check__container {
  position: relative;
  margin: 0 auto;
  max-width: 630px;
  padding-top: 15px; }
  @media (min-width: 768px) {
    .age-check__container {
      padding-top: 60px;
      padding-bottom: 35px; } }

.age-check__buttons {
  margin: 0 0 35px;
  border-bottom: 1px solid #b1743e;
  *zoom: 1; }
  .age-check__buttons:before, .age-check__buttons:after {
    display: table;
    content: " "; }
  .age-check__buttons:after {
    clear: both; }

.age-check__no,
.age-check__yes {
  position: relative;
  width: 50%;
  float: left;
  text-align: center;
  text-transform: uppercase;
  height: 75px;
  line-height: 75px;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0;
  background: transparent; }
  .age-check__no:active,
  .age-check__yes:active {
    box-shadow: none; }
  .age-check__no svg,
  .age-check__yes svg {
    margin-bottom: 4px; }

.age-check__no svg {
  transform: rotate(180deg);
  margin-right: 15px; }

.age-check__no:hover svg, .age-check__no:focus svg {
  transform: rotate(180deg) translateX(10px); }

.age-check__yes {
  border-left: 1px solid #b1743e; }
  .age-check__yes svg {
    margin-left: 15px; }
  .age-check__yes:hover svg, .age-check__yes:focus svg {
    transform: translateX(10px); }

.age-check__info {
  margin: 0 10px;
  font-size: 14px;
  font-size: 1.4rem; }
  .age-check__info a {
    color: #949494; }
    .age-check__info a:hover, .age-check__info a:focus {
      color: #b1743e; }
  @media (min-width: 768px) {
    .age-check__info {
      margin: 0 50px;
      font-size: 16px;
      font-size: 1.6rem; } }

.age-check__message {
  color: #000;
  margin-bottom: 20px; }

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.rte {
  *zoom: 1;
  margin-bottom: 20px; }
  .rte:before, .rte:after {
    display: table;
    content: " "; }
  .rte:after {
    clear: both; }
  .rte em {
    color: #b1743e;
    font-style: normal; }
  .rte p {
    text-indent: 10px; }
  .rte p + h2, .rte p + h3, .rte ul + h2, .rte ul + h3, .rte ol + h2, .rte ol + h3 {
    margin-top: 3.5em; }
  .rte h2, .rte h3 {
    text-transform: uppercase;
    font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    color: #a6a6a6;
    font-weight: 600;
    margin-bottom: 1.6em;
    letter-spacing: 1px; }
  .rte h3 {
    text-transform: none; }
  .rte ul {
    list-style: none; }
    .rte ul li {
      position: relative; }
      .rte ul li:before {
        content: "»";
        color: #b1743e;
        display: block;
        position: absolute;
        top: -1px;
        left: -20px; }
  @media (min-width: 768px) {
    .rte {
      font-size: 21px;
      font-size: 2.1rem; }
      .rte p {
        text-indent: 20px; }
      .rte + dl {
        margin-top: 45px; } }

@media (max-width: 766px) {
  .item-description-list {
    border-top: none;
    /* dd {
      padding: 5px;
    }*/ }
    .item-description-list dd, .item-description-list dt {
      padding: 10px 0;
      width: 50%;
      float: left;
      border-top: 1px solid #a6a6a6; }
      .item-description-list dd:after, .item-description-list dt:after {
        content: '';
        display: block;
        clear: both; } }

.rte--small {
  font-size: 18px;
  font-size: 1.8rem; }

.rte--sans {
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif; }

.rte--compact p {
  margin-bottom: 0.8em; }

.rte--first-letter p:first-of-type, .history-section--first-letter p:first-of-type {
  text-indent: 0; }
  .rte--first-letter p:first-of-type:first-letter, .history-section--first-letter p:first-of-type:first-letter {
    font-weight: 600;
    font-size: 58px;
    float: left;
    margin: 18px 18px 0 0; }
    @media screen and (min-width: 768px) {
      .rte--first-letter p:first-of-type:first-letter, .history-section--first-letter p:first-of-type:first-letter {
        font-size: calc(58px + 14 * (100vw - 768px) / 432); } }
    @media screen and (min-width: 1200px) {
      .rte--first-letter p:first-of-type:first-letter, .history-section--first-letter p:first-of-type:first-letter {
        font-size: 72px; } }

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0; }
  .center-block:before, .center-block:after {
    display: table;
    content: " "; }
  .center-block:after {
    clear: both; }
  .center-block .button {
    float: none;
    display: inline-block; }

section {
  position: relative; }

article {
  *zoom: 1;
  margin: 0 0 20px; }
  article:before, article:after {
    display: table;
    content: " "; }
  article:after {
    clear: both; }

.article-detail__image {
  margin-bottom: 1.5em; }

.note {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;
  font-size: 16px;
  font-size: 1.6rem; }
  .note:before, .note:after {
    display: table;
    content: " "; }
  .note:after {
    clear: both; }
  .note ul, .note p {
    margin-bottom: 1em; }

.note--success {
  color: #39963a;
  background-color: #eff9ef; }

.note--error {
  color: #f1062c;
  background-color: #fff7f8; }

.cycle-slideshow {
  width: 100%;
  position: relative; }

.cycle-pager {
  width: 100%;
  text-align: center; }
  .cycle-pager span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .cycle-pager span:hover, .cycle-pager span:focus {
      transition: none; }
  .cycle-pager span:hover, .cycle-pager span:focus, .cycle-pager .cycle-pager-active {
    text-indent: 0; }

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 0 2em -5px; }
  .social:before, .social:after {
    display: table;
    content: " "; }
  .social:after {
    clear: both; }

.social__item {
  padding: 0 0 5px 5px;
  float: left; }

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: #b1743e;
  text-decoration: none;
  text-align: center;
  overflow: hidden; }

.social-grid {
  margin: 20px 0 0; }
  .social-grid .grid__item:nth-last-child(-n+2) {
    display: none; }
  @media (min-width: 768px) {
    .social-grid {
      margin-top: 70px; } }
  @media (min-width: 1400px) {
    .social-grid .grid__item:nth-last-child(-n+2) {
      display: block; } }

.social-grid__item {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  text-align: center; }
  .social-grid__item a {
    text-decoration: none;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #b1743e; }
    .social-grid__item a:hover, .social-grid__item a:focus {
      opacity: 0.8; }
      .social-grid__item a:hover .icon, .social-grid__item a:focus .icon {
        transform: translateY(-2px) scale(1.05); }

.social-grid__item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .social-grid__item-content img {
    width: 100%; }
  @supports (object-fit: cover) {
    .social-grid__item-content img {
      height: 100%;
      object-fit: cover; } }

.social-grid__item--follow {
  overflow: visible; }
  .social-grid__item--follow .social-grid__item-content {
    position: absolute;
    z-index: 100;
    top: -25px;
    left: -25px;
    right: -25px;
    bottom: -25px;
    background-color: #fff;
    box-shadow: 0px 35px 60px rgba(50, 7, 7, 0.15); }
  .social-grid__item--follow a:hover, .social-grid__item--follow a:focus {
    opacity: 1; }

.social-grid__item-text {
  text-transform: uppercase;
  max-width: 140px;
  line-height: 1.7em;
  letter-spacing: 1px;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  margin: 40px auto 0;
  font-size: 14px;
  font-size: 1.4rem;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .social-grid__item-text a {
    text-decoration: none; }
  .social-grid__item-text .icon {
    display: block;
    position: relative;
    margin: 0 auto 18px; }

.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .hero {
      margin-bottom: 90px; } }

.hero__visual {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }
  .hero__visual img {
    width: 100%; }
  @supports (object-fit: cover) {
    .hero__visual img {
      height: 100%;
      object-fit: cover; } }

@media (min-width: 768px) {
  .hero--home .hero__content {
    min-height: 100vh; } }

@media (min-height: 1400px) and (min-width: 1024px) {
  body.flex-edge-ie-fix .hero--home {
    margin-bottom: 175px; } }

@media (min-width: 768px) {
  body.flex-edge-ie-fix .hero--home .hero__content {
    min-height: 100vh; } }

.hero__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 0 30px;
  margin-bottom: 20px;
  /*  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);*/ }
  .hero__content h1 {
    color: #fff; }
  @media (min-width: 768px) {
    .hero__content {
      margin-bottom: 50px; } }

.hero__logo {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .hero__logo {
      margin-bottom: 90px; } }

.hero--history {
  z-index: 100;
  background-color: #eeeeee; }
  .hero--history .hero__content {
    color: #404040; }
    .hero--history .hero__content h1 {
      color: #b1743e;
      font-size: 30px;
      margin-bottom: 0.15em; }
      @media screen and (min-width: 768px) {
        .hero--history .hero__content h1 {
          font-size: calc(30px + 38 * (100vw - 768px) / 432); } }
      @media screen and (min-width: 1200px) {
        .hero--history .hero__content h1 {
          font-size: 68px; } }
  @media (min-width: 768px) {
    .hero--history {
      font-size: 24px;
      font-size: 2.4rem; }
      .hero--history .hero__content {
        max-height: 50vh;
        min-height: 50vh; } }
  @media (min-width: 1024px) {
    .hero--history .icon-scroll-down {
      position: relative;
      top: 6vh; } }

.hero__visual--history {
  top: 30vh;
  height: 100%;
  max-height: 80vh;
  bottom: inherit; }
  @media (min-width: 768px) {
    .hero__visual--history {
      top: 45vh; } }

.hero___arrow-down {
  display: block;
  text-decoration: none;
  width: 41px;
  height: 41px;
  border: 1px solid #b1743e;
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%); }
  .hero___arrow-down .hero___arrow-down-icon {
    transform: rotate(90deg);
    margin-left: 2px; }
  .hero___arrow-down:hover, .hero___arrow-down:focus, .hero___arrow-down:active {
    text-decoration: none; }
    .hero___arrow-down:hover .hero___arrow-down-icon, .hero___arrow-down:focus .hero___arrow-down-icon, .hero___arrow-down:active .hero___arrow-down-icon {
      transform: rotate(90deg) translateX(5px); }
  @media (min-width: 768px) {
    .hero___arrow-down {
      bottom: 40px;
      width: 51px;
      height: 51px; } }
  @media (min-height: 700px) and (min-width: 1024px) {
    .hero___arrow-down {
      bottom: 14vh; } }

.hero___arrow-down-icon {
  font-style: normal;
  transform: rotate(90deg) translateY(-3px);
  text-align: center;
  display: block;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .hero___arrow-down-icon:before {
    content: "»";
    color: #fff;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 41px; }
  @media (min-width: 768px) {
    .hero___arrow-down-icon:before {
      font-size: 28px;
      font-size: 2.8rem;
      line-height: 51px; } }

.section-header {
  text-align: center; }
  @media (min-width: 768px) {
    .section-header {
      font-size: 21px;
      font-size: 2.1rem; } }

.section-header__title {
  margin-bottom: 0.3em; }

.section-header--news {
  margin: 30px 0; }
  @media (min-width: 1024px) {
    .section-header--news {
      margin: 65px 0; } }
  @media (min-width: 1680px) {
    .section-header--news {
      margin-top: 110px;
      margin-bottom: 100px; } }

.thumbs-list {
  *zoom: 1;
  list-style: none;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  margin: 40px auto;
  max-width: 1140px; }
  .thumbs-list:before, .thumbs-list:after {
    display: table;
    content: " "; }
  .thumbs-list:after {
    clear: both; }
  .thumbs-list li {
    width: 50%;
    display: block;
    float: left;
    margin-bottom: 30px; }
  @media (min-width: 768px) {
    .thumbs-list {
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 90px;
      margin-bottom: 150px; }
      .thumbs-list li {
        width: 33.33%;
        margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .thumbs-list li {
      width: 16.66%; } }

@media (min-width: 768px) {
  .thumbs-list--home {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end; }
    .thumbs-list--home li {
      margin-bottom: 30px; } }

@media (min-width: 1024px) {
  .thumbs-list--home li {
    margin-bottom: 0; } }

.thumbs-list__link {
  display: block;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  color: #a5a5a5;
  position: relative; }
  .thumbs-list__link:hover, .thumbs-list__link:focus {
    color: #b1743e; }
    .thumbs-list__link:hover .thumbs-list__visual img, .thumbs-list__link:focus .thumbs-list__visual img {
      transform: translateY(-15px); }
    .thumbs-list__link:hover .thumbs-list__visual:before, .thumbs-list__link:focus .thumbs-list__visual:before {
      opacity: 0.25;
      transform: scale(1) translateY(-10px); }
    .thumbs-list__link:hover .thumbs-list__visual:after, .thumbs-list__link:focus .thumbs-list__visual:after {
      opacity: 0.4;
      transform: scale(0.9); }
    .thumbs-list__link:hover .thumbs-list__overlay, .thumbs-list__link:focus .thumbs-list__overlay {
      opacity: 1;
      transform: translateY(0); }
    .thumbs-list__link:hover .thumbs-list__text, .thumbs-list__link:focus .thumbs-list__text {
      opacity: 0; }

.thumbs-list__visual {
  position: relative;
  margin-bottom: 25px; }
  .thumbs-list__visual img, .thumbs-list__visual:before, .thumbs-list__visual:after {
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .thumbs-list__visual:before, .thumbs-list__visual:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    z-index: -1; }
  .thumbs-list__visual:before {
    top: 35px;
    margin-left: -70px;
    width: 140px;
    height: 200px;
    background: url("../images/logo-icon-brown.svg") center center no-repeat;
    background-size: contain;
    opacity: 0;
    transform: scale(1.05); }
  .thumbs-list__visual:after {
    bottom: -25px;
    margin-left: -72px;
    width: 140px;
    height: 60px;
    background: url("../images/product-shadow.png") center center no-repeat;
    background-size: contain;
    opacity: 0.8; }
  .thumbs-list__visual img {
    max-height: 180px; }
  @media (min-width: 768px) {
    .thumbs-list__visual img {
      max-height: 260px; } }

.thumbs-list__text {
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

@media (min-width: 768px) {
  .thumbs-list__text--label {
    position: absolute;
    top: 50%;
    right: 50px;
    width: auto;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    color: #b1743e;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 5px 10px;
    margin-top: -12px; } }

@media (min-width: 768px) and (min-height: 950px) {
  .thumbs-list__text--label {
    right: 60px; } }

.thumbs-list__overlay {
  position: absolute;
  bottom: -45px;
  left: 10px;
  right: 10px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  opacity: 0;
  color: #b1743e;
  transform: translateY(10px);
  line-height: 1.3em; }
  .thumbs-list__overlay .icon {
    display: block;
    position: relative;
    margin: 13px auto 0; }

.thumbs-list-title {
  text-align: center;
  margin-top: 40px;
  font-size: 30px; }
  @media screen and (min-width: 768px) {
    .thumbs-list-title {
      font-size: calc(30px + 10 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    .thumbs-list-title {
      font-size: 40px; } }
  @media (min-width: 768px) {
    .thumbs-list-title {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; } }

.thumbs-list--fixed .active .thumbs-list__link {
  opacity: 1; }
  .thumbs-list--fixed .active .thumbs-list__link .thumbs-list__visual:before {
    opacity: 0.5; }

.thumbs-list--fixed .active .thumbs-list__text--label {
  opacity: 0; }

@media (max-width: 766px) {
  .thumbs-list--fixed .active {
    display: none; } }

@media (min-width: 768px) {
  .thumbs-list--fixed {
    position: fixed;
    top: 50%;
    right: 30px;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);
    margin-top: 50px;
    z-index: 800; }
    .thumbs-list--fixed li {
      float: none;
      width: 100%;
      max-width: 60px; }
    .thumbs-list--fixed img {
      max-height: 70px; }
    .thumbs-list--fixed .thumbs-list__visual {
      margin-bottom: 16px; }
      .thumbs-list--fixed .thumbs-list__visual:before {
        width: 40px;
        height: 60px;
        margin-left: -20px;
        top: 5px;
        opacity: 0; }
      .thumbs-list--fixed .thumbs-list__visual:after {
        display: none; }
    .thumbs-list--fixed .thumbs-list__link {
      backface-visibility: hidden;
      opacity: 0.5; }
      .thumbs-list--fixed .thumbs-list__link:hover, .thumbs-list--fixed .thumbs-list__link:focus {
        opacity: 1; }
        .thumbs-list--fixed .thumbs-list__link:hover .thumbs-list__text--label, .thumbs-list--fixed .thumbs-list__link:focus .thumbs-list__text--label {
          opacity: 1; }
        .thumbs-list--fixed .thumbs-list__link:hover .thumbs-list__visual:before, .thumbs-list--fixed .thumbs-list__link:focus .thumbs-list__visual:before {
          transform: scale(1); }
        .thumbs-list--fixed .thumbs-list__link:hover .thumbs-list__visual img, .thumbs-list--fixed .thumbs-list__link:focus .thumbs-list__visual img {
          transform: translateY(0); } }

@media (min-width: 768px) and (min-height: 950px) {
  .thumbs-list--fixed {
    margin-top: 50px; }
    .thumbs-list--fixed img {
      max-height: 100px; }
    .thumbs-list--fixed .thumbs-list__visual:before {
      width: 58px;
      height: 85px;
      margin-left: -30px;
      top: 10px; } }

@media (min-width: 768px) and (min-height: 1000px) {
  .thumbs-list--fixed {
    margin-top: 16px; } }

.cta-list {
  margin: 0; }
  .cta-list .grid__item {
    padding-left: 0;
    border-bottom: 1px solid #e5dcd4; }
  @media (min-width: 768px) {
    .cta-list {
      margin-right: -1px;
      margin-bottom: -1px; }
      .cta-list .grid__item {
        border-right: 1px solid #e5dcd4; } }

.cta {
  position: relative;
  text-decoration: none;
  display: block;
  text-align: center;
  color: #b1743e;
  line-height: 1.5em; }
  .cta:hover, .cta:focus {
    color: #b1743e; }
    .cta:hover .cta__visual img,
    .cta:hover .cta__visual--product:before, .cta:focus .cta__visual img,
    .cta:focus .cta__visual--product:before {
      opacity: 0.3; }
    .cta:hover .cta__visual img, .cta:focus .cta__visual img {
      filter: blur(10px); }
    .cta:hover .cta__visual--product:before, .cta:focus .cta__visual--product:before {
      transform: scale(0.9) translateY(40px); }
    .cta:hover .cta__text, .cta:focus .cta__text {
      opacity: 0;
      transform: translateY(10px); }
    .cta:hover .cta__overlay, .cta:focus .cta__overlay {
      opacity: 1;
      transform: scale(1);
      visibility: visible; }
    .cta:hover .cta__overlay-text, .cta:focus .cta__overlay-text {
      transform: translateY(0px); }
    .cta:hover .icon, .cta:focus .icon {
      transform: translateX(0px);
      opacity: 1; }
  @media (min-width: 768px) {
    .cta {
      font-size: 21px;
      font-size: 2.1rem; } }

@media (min-width: 768px) {
  .cta--product .cta__content {
    padding-top: 50px; }
  .cta--product .cta__text {
    min-height: 65px; } }

@media (min-width: 1024px) {
  .cta--product .cta__content {
    padding-top: 90px; } }

.cta__content {
  padding: 30px; }

.cta__visual-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 109%;
  margin-bottom: 30px; }

.cta__visual {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .cta__visual img {
    display: inline-block;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    backface-visibility: hidden; }

.cta__visual--product {
  display: flex;
  align-items: flex-end;
  justify-content: center; }
  .cta__visual--product:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -35px;
    left: 50%;
    margin-left: -95px;
    z-index: -1;
    width: 189px;
    height: 82px;
    background: url("../images/product-shadow.png") center center no-repeat;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    opacity: 0.8; }
  .cta__visual--product img {
    height: 100%;
    max-height: 390px; }

.cta__visual--slashed {
  overflow: hidden; }
  .cta__visual--slashed img {
    width: 100%; }
  .cta__visual--slashed:before, .cta__visual--slashed:after {
    content: "";
    display: block;
    position: absolute;
    width: 44px;
    height: 20px;
    transform: rotate(-25deg);
    background-color: #efefef;
    z-index: 100; }
  .cta__visual--slashed:before {
    top: -10px;
    left: -10px; }
  .cta__visual--slashed:after {
    bottom: -10px;
    right: -10px; }

.cta__text {
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  @media (min-width: 768px) {
    .cta__text {
      min-height: 75px; } }

.cta__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.082);
  opacity: 0;
  z-index: 200;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  transform: scale(0.98);
  backface-visibility: hidden;
  visibility: hidden; }

.cta__overlay-content {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-width: 22px;
  border-style: solid;
  border-image: url("../images/border.svg") 22 22 round;
  display: flex;
  justify-content: center;
  align-items: center; }
  .cta__overlay-content .icon {
    display: block;
    position: relative;
    margin: 13px auto 0;
    transform: translateX(-15px);
    opacity: 0; }

.cta__overlay-text {
  text-transform: uppercase;
  max-width: 136px;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  transform: translateY(20px); }

.item-header {
  *zoom: 1;
  margin-bottom: 30px; }
  .item-header:before, .item-header:after {
    display: table;
    content: " "; }
  .item-header:after {
    clear: both; }

.item-header--lined {
  border-bottom: 1px solid #b1743e;
  margin-bottom: 50px; }

.item-header__title {
  margin-bottom: 6px; }

.item-header__subtitle {
  color: #a6a6a6;
  text-transform: uppercase;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  margin-bottom: 0; }
  .item-header__subtitle .divider {
    display: inline-block;
    margin: 0 12px; }

.item-footer {
  margin: 30px 0 10px; }
  .item-footer .button {
    margin: 0; }

.lined-list {
  list-style: none;
  margin: 0 0 3em;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif; }
  .lined-list li {
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0; }
  @media (min-width: 768px) {
    .lined-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .lined-list li {
        width: calc(50% - 13px); } }
  @media (min-width: 1400px) {
    .lined-list li {
      padding: 20px 0; } }

/* =============================================================================
   PAGES
   ========================================================================== */
/* History */
.history-section {
  position: relative; }
  .history-section p {
    text-indent: 0; }
  .history-section + .history-section {
    border-top: 1px solid #e8e8e8;
    padding-top: 50px;
    margin-top: 60px; }
  @media (min-width: 768px) {
    .history-section:first-of-type .history-section__title {
      color: #fff; } }

h2.history-section__title {
  font-size: 30px;
  font-family: "calluna", Times, Times New Roman, serif;
  color: rgba(64, 64, 64, 0.35);
  text-transform: none;
  margin: 0 0 0.5em;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2.history-section__title {
      font-size: calc(30px + 24 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h2.history-section__title {
      font-size: 54px; } }
  h2.history-section__title:before {
    content: "";
    display: inline-block;
    width: 50px;
    border-top: 1px solid #b1743e;
    vertical-align: middle;
    margin-right: 30px; }
  @media (min-width: 768px) {
    h2.history-section__title {
      position: absolute;
      top: 50%;
      right: -335px;
      transform: translateY(-50%);
      width: 310px; } }
  @media (min-width: 1680px) {
    h2.history-section__title {
      right: -360px; }
      h2.history-section__title:before {
        width: 100px; } }

/* News */
@media (min-width: 768px) {
  .articles-list .grid__item:nth-child(3n+4) {
    clear: left; } }

@media (min-width: 1200px) {
  .articles-list {
    margin-left: -50px; }
    .articles-list .grid__item {
      padding-left: 50px; } }

@media (min-width: 1400px) {
  .articles-list {
    margin-left: -75px; }
    .articles-list .grid__item {
      padding-left: 75px; } }

.article-contained {
  margin-bottom: 20px; }
  @media (min-width: 1200px) {
    .article-contained {
      margin-bottom: 55px; } }

.article-contained--has-visual .article-contained__content:before {
  display: none; }

.article-contained__visual {
  display: block;
  position: relative;
  z-index: 5; }
  .article-contained__visual a {
    display: block; }
    .article-contained__visual a:hover, .article-contained__visual a:focus {
      transform: scale(1.08);
      box-shadow: 0 0 55px rgba(193, 180, 180, 0.8); }

.article-contained__content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  overflow: hidden; }
  .article-contained__content:before, .article-contained__content:after {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 25px;
    background-color: #efefef;
    transform: rotate(-24deg); }
  .article-contained__content:before {
    top: -18px;
    left: -18px; }
  .article-contained__content:after {
    bottom: -18px;
    right: -18px; }
  @media (min-width: 1024px) {
    .article-contained__content {
      padding: 30px; } }
  @media (min-width: 1400px) {
    .article-contained__content {
      padding: 50px; } }

.article-contained__date {
  text-transform: uppercase;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  color: #9f9f9f;
  font-size: 12px;
  font-size: 1.2rem;
  margin-bottom: 5px; }

.article-contained__body {
  margin-bottom: 1em; }

.article-contained__footer {
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "calluna-sans", Museo, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 1.4rem; }
  .article-contained__footer .icon {
    margin-right: 8px; }
  .article-contained__footer a {
    text-decoration: none;
    color: #9f9f9f; }
    .article-contained__footer a:hover, .article-contained__footer a:focus {
      color: #b1743e; }

.pagination p {
  *zoom: 1; }
  .pagination p:before, .pagination p:after {
    display: table;
    content: " "; }
  .pagination p:after {
    clear: both; }

.pagination-pager {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  float: left;
  font-feature-settings: "tnum";
  font-variant-numeric: lining-nums;
  line-height: 1em;
  border: 1px solid #ddd;
  margin-right: -1px; }

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm; }
  html {
    font-size: 10px; }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit; }
  a, a:visited {
    text-decoration: underline; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  .page-footer, .main-nav {
    display: none; }
  .medium--one-half {
    width: 50%; }
  .medium--two-thirds {
    width: 66.66%; }
  .medium--one-third {
    width: 33.33%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-quarter {
    width: 25%; } }
