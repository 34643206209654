
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-family: $sansFont;
  color: #9d9d9d;
  @include fs(12);
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 20px; margin-bottom: 2px; display: block;
  background: #fff; height: 45px;
  line-height: inherit; width: 100%;
  border: 0;
  border-bottom: 1px solid #320707;

  &:focus {
    outline: 0;
    border-color: $accent;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto; vertical-align: top; resize: vertical;
  width: 100%; height: 115px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

.checkbox {
  position: relative;

  a {
    text-decoration: none;
  }

  label {
    text-transform: none;
    @include fs(18);
    font-family: $defaultFont;
    color: $black;
  }

  input[type="checkbox"] {
    visibility: hidden;
    position: absolute; top: 0; left: 0;

    &:checked + label:after {
      opacity: 1;
    }
  }

  label {
    cursor: pointer;

    &:before {
      content: ""; display: inline-block; vertical-align: middle;
      width: 20px; height: 20px;
      border: 1px solid $accent; background-color: #fff;
      margin-right: 12px;
      margin-top: -2px;
    }

    &:after {
      content: ""; display: block;
      position: absolute; top: 8px; left: 3px;
      width: 14px; height: 14px;
      background: url('../images/checkmark.svg') center center no-repeat;
      background-size: cover;
      z-index: 1;
      transition: $transitionDefault;
      opacity: 0;
    }
  }
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button {
  float: left; display: block; clear: both;
  transition: $transitionDefault;
  outline: 0; line-height: 1.2em;
  padding: 5px 26px;
  margin: 10px 0 0;
  background-color: transparent;
  text-decoration: none;
  color: $black;
  text-transform: uppercase;
  border: 14px solid transparent;
  border-image: url('../images/border.svg') 21 round;
  font-family: $sansFont;
  @include fs(12);
  letter-spacing: 1px;
  text-align: center;
  font-variant-numeric: lining-nums;

  .icon {
    fill: $black;
    margin-left: 15px;
  }

  &:hover, &:focus, &:active {
    background-color: transparent;

    .icon {
      transform: translateX(5px);
    }
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.2);
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  @media #{$huge} {
    .icon {
      fill: $black;
      margin-left: 35px;
    }
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

.button--full {
  width: 100%;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: #999; font-style: italic; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;
    margin-bottom: 0;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 25px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }
