
/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Font Stacks
@mixin default-font($size: 18, $weight: 400, $lineHeight: 1.7em) {
  font-family: $defaultFont;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-family: $sansFont;
  font-weight: 500;
}


// Body

p {
  line-height: 1.7em;
  margin-bottom: 1.5em;
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }
}

.tdn {
  text-decoration: none;
}

.tdn--invisible {
  color: $black;

  &:hover, &:focus {
    color: $accent;
  }
}

a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}

.accent {
  color: $accent;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1, h2, .h2 {
  font-family: $serifFont;
  line-height: 1em;
  font-feature-settings: "swsh" 1, "liga" 1, "clig" 0, "calt" 0, "kern";
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 42px, 48px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 28px);
}

h3, .h3 {
  @include fs(14);
  text-transform: uppercase;
  color: #a6a6a6;
  margin-top: 6px;
  line-height: 1.5em;
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 20px;

  li {
    line-height: 1.5em;
    margin-bottom: 8px;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }
}

ol {
  list-style: none;
  counter-reset: ol-counter;

  li {
    position: relative;

    &:before {
      content: counter(ol-counter);
	    counter-increment: ol-counter;
      color: $accent;
      display: block;
      position: absolute; top: -1px; left: -20px;
      font-feature-settings: "tnum";
      font-variant-numeric: lining-nums;
    }
  }
}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
  border-top: 1px solid $accent;
  font-family: $sansFont;
  display: flex;
  flex-wrap: wrap;
}

dt, dd {
  line-height: 1.5em;
  padding: 20px 0;

  + dt {
    border-top: 1px solid $gray;
  }

  @media #{$medium} {
    float: left;
    padding: 15px 0;

    + dd {
      border-top: 1px solid $gray;

      &:first-of-type {
        border-top-color: transparent;
      }
    }
  }
}

dt {
  text-transform: uppercase;
  @include fs(14);
  color: $gray;
  letter-spacing: 1px;

  @media #{$maxMedium} {
    padding-bottom: 0;
  }

  @media #{$medium} {
    width: 170px;
    padding-top: 20px;
  }

  @media #{$huge} {
    width: 195px;
  }
}

dd {
  margin-bottom: 0.5em;

  @media #{$maxMedium} {
    padding-top: 10px;
  }

  @media #{$medium} {
    width: calc(100% - 170px);
  }

  @media #{$huge} {
    width: calc(100% - 195px);
  }
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}
